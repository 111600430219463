import { ForgotPass as ForgotPasswordForm } from "../components/shared/AuthForm/AuthForm"
import { BasicLayout } from "../components/shared/Layout"
//import { RecaptchaProvider } from "../components/shared/RecaptchaProvider"
// import { asyncLoadScript } from "../helpers/components"

export const ForgotPass = () => {
    return (
        //<RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <ForgotPasswordForm />
            </BasicLayout>
        //</RecaptchaProvider>


    )
}