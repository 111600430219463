import { BasicLayout } from "../components/shared/Layout"
import React from "react"
import { AppDownloader } from "../components/pages/appDownloader";
/*
{
    "title":"Izichange APP",
    "description":"Simple, facile et sécurise",
    "subtitle":"Achetez et vendez plus <br/>Facilement et plus Rapidement !",
    "downloadOn":"Télécharger sur"
}*/
export const AppDowload = () => {
   
    
    return (
        <BasicLayout>
            <AppDownloader/>
        </BasicLayout>
    )
}