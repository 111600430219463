// import { useTranslation } from "react-i18next"
import { clx } from "../../../../helpers/components"
import { A2fF } from "../AuthForm"
import style from "./style.module.scss"
export const A2fForm = () => { 
    return (
            <div style={{flex:1}}>
                <div className={clx(style.pageContainer, "container")}>
                    <A2fF/>
                </div>    
            </div>
    )
}