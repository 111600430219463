import { clx } from "../../../../helpers/components"
import { Button } from "../../../ui/Button"
import { PhoneField, SelectBox, TextField } from "../../../ui/TextField"
import style from "./style.module.scss"
import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useRef, useContext, useMemo, useEffect } from "react" 
import { Trans, useTranslation } from "react-i18next" 
import { RecaptchaContext } from "../../RecaptchaProvider"
import axios from "axios"
import { Alert } from "../../Alert"
import { AUTH_TOKEN_KEY, login, redirectToSecure, setCookie, isConnected, BASE_URL } from "../../../../helpers/func" 
import { AlertCounter } from "../../../pages/emailPage"
//auth google authentication if token exist

const getMessageOn = (messages, path) => {
    if (typeof path === "string" && !path.includes(" ") && path.includes(".")) {
        let result = null
        eval(`  result=messages.${path};`)
        return result;
    }
    return path
}
const Twofactor = () => {
    const { t, i18n } = useTranslation("translations")
    const { apiKey } = useContext(RecaptchaContext)
    const errorElementRef = useRef()
    const { state } = useLocation()
    const [form, setForm] = React.useState({ 
        otp: {
            value: "",
            isValid: true,
            errorMessage: "validations.password"

        },
        showGroupError: null,
        showGroupSuccess: null
    })
    const messages = {
        validations: { 
            formInvalid: t("login-page.validations.formInvalid"), 
            unexpectedError: t("login-page.validations.unexpectedError"),
            recaptcha: t("login-page.validations.recaptcha"),
            csrf: t("login-page.validations.csrf")
        }
    }
    const navigate = useNavigate()
    useEffect(() => { 
        if (!(state?.auth_token)) {
            navigate("/login")
        }
    }, [state?.auth_token]) 
 
    const [inputData, setInputData] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = React.useState(false) 
    const csrf = localStorage.getItem('csrf');
    const [isSent, setIsSent] = React.useState(null) 
    const handleResendOtp = () => {
        
        window.grecaptcha.ready(function callback(){
            window.grecaptcha.execute(apiKey)
                .then((token) => {
                    if (loading || isSent) return;
                    setIsSent(null)

                    const params = new URLSearchParams();
                    params.append("auth_token", state?.auth_token);
                    params.append("token", token);
                    params.append("csrf_token", csrf);

                    const options = {
                        Accept: 'application/json',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'lang': i18n.language,
                            'appversion': "2.0.1"
                        },
                        data: params,
                        url: BASE_URL + "/api/pu_resend_mail_otp/web"
                    };

                    setLoading(true)
                    axios(options)
                        .then(({ data }) => {  
                            if (!data.succes) {
                                if (data.recaptcha) {
                                    setForm(prev => ({
                                        ...prev,
                                        showGroupError: "validations.recaptcha",
            
                                    }))
            
                                } else if (data.statusCode == "INVALID_FORM") {
                                    setForm(prev => ({
                                        ...prev,
                                        showGroupError: "validations.csrf",
                                    }))
                                    setTimeout(() => {
                                        window.location.href = BASE_URL
                                    }, 5000);
                                }
                            }else if(data.succes){
                                setIsSent(data.succes)
                                setTimeout(()=>setIsSent(null),5000)
                            }
                           
                        })
                        .catch(e => { 
                            setIsSent(false)
                        })
                        .finally(() => setLoading(false))
                })
        })
    }
    const handleSubmit = () => {

        if (inputData) {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(apiKey, { action: 'submit' }).then(function (token) {
                    if(token && !loading)
                        check_a2f(inputData, token)
                });
            });


        }
    }

    const check_a2f = (code, token) => {
        const params = new URLSearchParams();
        params.append("auth_token", state?.auth_token);
        params.append("token", token);
        params.append('code', code);
        params.append("csrf_token", csrf);
        setLoading(true)

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'APPVERSION': "2.0.1"
            },
            data: params,
            url: BASE_URL + "/api/pu_check_mail_2fa"
        };

        axios(options)
            .then(({ data }) => {
                if (data.succes) {
                    setLoading(true)
                    setCookie(AUTH_TOKEN_KEY, data.token)
                    redirectToSecure("dashboard/pu_echange", i18n.language, true, false);
                } else {
                    if (data.recaptcha) {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.recaptcha",

                        }))

                    } else if (data.statusCode == "INVALID_FORM") {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.csrf",
                        }))
                        setTimeout(() => {
                            window.location.href = BASE_URL
                        }, 5000);
                    }
                }
            })
            .catch(e => {
                setError(t('af-page.validations.validCode'))
            }) .finally(() => setLoading(false))

    }



    return (
        <> 
            <form style={{ width: '80%' ,maxWidth:"450px",minWidth:"330px"}} onSubmit={(e) => {
                e.preventDefault()
                if (inputData === '') { setError(t('af-page.validations.codeEmpty')) }
                if (!loading && inputData !== '') setError(''); handleSubmit()
            }}>
                <div className={clx(style.form)}>
                    <h2>{t('af-page.email_otp.title')}</h2>
                    <div ref={errorElementRef}>
                        <Alert theme={form.showGroupError ? "danger" : "success"} open={!!(form.showGroupError || form.showGroupSuccess)} onClose={(e) => {
                            e.preventDefault();
                            setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccess"]: false }))
                        }}>
                            <p className="" style={{ width: "100%" }} dangerouslySetInnerHTML={{
                                __html:`<span>${getMessageOn(messages, form.showGroupError ?? form.showGroupSuccess)}</span>`
                            }}>
                            </p>
                        </Alert>
                    </div>
                    <TextField
                        label={t('af-page.email_otp.label')}
                        placeholder={t('af-page.form.placeholder')}
                        type="text"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                    />
                    {error && <p className={style.errorMsg}>{error}</p>}

                    <Button
                        type="submit"
                        loading={loading}
                        onClick={handleSubmit}
                        className={style.ctaButton}
                        ariaLabel={t('af-page.form.placeholder')}

                    >
                        {t('af-page.ctaButton')}
                    </Button> 
                    {isSent !== null && <AlertCounter isEmailSent={isSent} timeOut={5} />} 
                      
                    <div onClick={handleResendOtp} className={style.acceptConditions} style={{ display: "flex", justifyContent: "right",cursor: "pointer"}}>
                        <div>
                            <span> {t("af-page.email_otp.noreceived")} </span> <span className={style.a}>{t("af-page.email_otp.resendOtpcode")}</span>
                        </div> 
                    </div>
                </div>
            </form>  
        </>
    )
}

export { Twofactor }