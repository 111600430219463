
import { A2fCherker } from "../components/shared/AuthForm/A2f/Check"
import { BasicLayout } from "../components/shared/Layout"
import { RecaptchaProvider } from "../components/shared/RecaptchaProvider"


export const Twofactor = () => {
    return (
        <RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <A2fCherker/>
            </BasicLayout>
        </RecaptchaProvider>
    )
}