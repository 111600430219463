import { EmailPage } from "../components/pages/emailPage";
import { BasicLayout } from "../components/shared/Layout";
import { RecaptchaProvider } from "../components/shared/RecaptchaProvider";

export function EmailConfirmation(){
    return(
        <RecaptchaProvider apiKey={"6Lf0z7gnAAAAALTWhB4NC9VbLAvt9XeP1pMFGeOu"}>
            <BasicLayout>
                <EmailPage/>
            </BasicLayout>
        </RecaptchaProvider>
    )
}